
import Vue, { PropType } from "vue";

import { DialogCloseType, DialogI } from "@/types/dialog";

import BaseDialog from "@/components/dialog/BaseDialog.vue";
import { sample } from "lodash";
import Btn from "@/components/v2/base/Btn.vue";

export default Vue.extend({
  name: "LessonFinishedDialog",
  components: {
    BaseDialog,
    Btn,
  },

  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },

  computed: {
    trophyIcon() {
      const iconIndexes = [1, 2, 3, 4, 5, 6];
      const index = sample(iconIndexes);
      return require(`@/assets/trophy${index}.png`);
    },
  },

  methods: {
    goToRepetition() {
      this.dialog.close({
        type: DialogCloseType.SUBMITTED,
        payload: {
          action: "goToRepetition",
        },
      });
    },
    backToLesson() {
      this.dialog.close({
        type: DialogCloseType.SUBMITTED,
        payload: {
          action: "backToLesson",
        },
      });
    },
  },
});
